.react-js-cron {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.my-project-cron-field {
  margin-bottom: 20px !important;
  padding: 3px;
  > span {
    font-family: "Noto Sans Thai", sans-serif;
  }
}

.my-project-cron-field .my-project-cron-select .ant-select-selector {
  width: 100px;
  height: 56px;
  border-color: #333333;
}

.my-project-cron-period .my-project-cron-select .ant-select-selector {
  width: 236px;
  height: 56px;
  align-items: center;

  > span {
    font-family: "Noto Sans Thai", sans-serif;
  }
}

.my-project-cron-minutes .ant-select-selector {
  width: 100px;
  height: 56px;
}

.my-project-cron.my-project-cron-months .ant-select-selector {
  width: 200px;
}

.my-project-cron.my-project-cron-months {
  order: 1;
}

.my-project-cron.my-project-cron-month-days {
  order: 2;
}

.my-project-cron-clear-button:active {
  content: "";
}

/* OVERRIDE Cron Format */
.my-project-cron .react-js-cron-clear-button.my-project-cron-clear-button {
  background-color: white;
  border-color: #004c85;
  color: #004c85;
  font-family: "Noto Sans Thai", sans-serif;
  margin-bottom: 20px !important;
  width: 92px;
  height: 34px;
  margin-left: 24px;
  display: flex;
}

.my-project-cron
  .react-js-cron-clear-button.my-project-cron-clear-button
  span::after {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 82px;
  background: white;
  text-align: center;
  margin: 4px;
  line-height: 24px;
  vertical-align: middle;
  font-family: "Noto Sans", "Noto Sans Thai", sans-serif;
}

.my-project-cron.th
  .react-js-cron-clear-button.my-project-cron-clear-button
  span::after {
  content: "รีเซ็ต";
}

.my-project-cron.en
  .react-js-cron-clear-button.my-project-cron-clear-button
  span::after {
  content: "RESET";
}
